<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="searchtime" style="margin-right: 0.4rem">
        <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('lang.to')"
          :start-placeholder="$t('lang.Startdate')"
          :end-placeholder="$t('lang.Enddate')"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="picker"
        >
        </el-date-picker>
      </div>
      <div class="select_b" style="margin-right: 0.2rem">
        <span style="color: #bbbbbb">{{ $t("lang.workOrderStatus") }}：</span>
        <el-select
          style="height: 0.4rem; width: 1.5rem"
          v-model="value1"
          :placeholder="$t('lang.Please')"
          popper-class="select_a"
          @change="stauts"
        >
          <el-option :label="$t('lang.whole')" value=""></el-option>
          <el-option :label="$t('lang.Assigned')" :value="1"></el-option>
          <el-option :label="$t('lang.Closed')" :value="2"></el-option>
          <el-option :label="$t('lang.Tobeclosed')" :value="3"></el-option>
        </el-select>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="id"
          :label="$t('lang.workOrderNo')"
          align="center"
        >
          <template #default="scope">
            <div style="position: relative">
              <span class="texts">{{ scope.row.id }}</span>
              <div class="messige" v-if="scope.row.readStatus == 0"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.NameModification')"
          align="center"
          width="300"
          show-overflow-tooltip
        >
          <template #default="scope">
            <span class="texts">{{ scope.row.description | descr }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.problemDescription')" align="center">
          <template #default="scope">
            <span v-if="scope.row.type == 1">{{
              $t("lang.userRegistrationClass")
            }}</span>
            <span v-if="scope.row.type == 2">{{
              $t("lang.liveBroadcastTerminalClass")
            }}</span>
            <span v-if="scope.row.type == 3">{{
              $t("lang.streamingMediaPushClass")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          :label="$t('lang.mailbox')"
          align="center"
        >
        </el-table-column>
        <el-table-column :label="$t('lang.cellphonenumber')" align="center">
          <template #default="scope">
            <span v-if="scope.row.phone">{{ scope.row.phone }}</span>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('lang.state')" align="center">
          <template #default="scope">
            <span v-if="scope.row.state == 1">{{ $t("lang.Assigned") }}</span>
            <span v-if="scope.row.state == 2">{{ $t("lang.Closed") }}</span>
            <span v-if="scope.row.state == 3">{{ $t("lang.Tobeclosed") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          :label="$t('lang.submissionTime')"
          width="220"
          align="center"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="center"
          width="200"
        >
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip
                effect="dark"
                :content="$t('lang.details')"
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="handleEdit(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/xiangqing.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/xiangqing2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise" v-if="scope.row.state != 2">|</div>
              <el-tooltip effect="dark" :content="$t('lang.closeWorkOrder')" placement="left" v-if="scope.row.state != 2">
                <div
                  class="bottom_imgs bottom_delete"
                  @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                  @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                  @click="closemsg(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag2 === 0"
                    style="width: 0.18rem"
                    src="@/assets/imgs/bottom/guanbi.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag2 === 1"
                    style="width: 0.18rem"
                    src="@/assets/imgs/bottom/guanbi2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
            <!-- <el-button
              size="small"
              class="buttons"
              style="background-color: #1061c5"
              @click="handleEdit(scope.row)"
              >{{ $t("lang.details") }}
            </el-button>
            <el-button
              v-if="scope.row.state != 2"
              size="small"
              class="buttons"
              style="background-color: rgb(48, 123, 55)"
              @click="closemsg(scope.row)"
              >{{ $t("lang.closeWorkOrder") }}
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div
        style="display: flex; align-items: center"
        v-if="tableData.list && tableData.list.length > 0"
      >
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          background
          v-if="tableData.list && tableData.list.length > 0"
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as workmsg from "@/api/manage.js";
export default {
  data() {
    return {
      // seenOnetext: this.$t("lang.closeWorkOrder"),
      // seenFreetext: this.$t("lang.details"),

      time: "",
      value1: "",
      tableData: [],
      parsm: {
        page: 1,
        size: 10,
        state: "",
        createTime: "",
        stopTime: "",
        userId: "",
      },
    };
  },
  mounted() {
    if (this.$route.query.page) {
      this.parsm.page = this.$route.query.page;
    }
    this.getList();
  },
  filters: {
    descr(val) {
      let data = JSON.parse(val);
			let sk=''
			 if(data.length>0){
				 sk=data[data.length-1].description
			 }
			 return sk
    },
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag1 = 1;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag2 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag1 = 0;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag2 = 0;
          }
        }
      }
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //时间筛选
    picker(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.createTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.createTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //状态筛选
    stauts(e) {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.state = e;
      this.getList();
    },
    //获取工单列表
    async getList() {
      let res = await workmsg.workList(this.parsm);
      console.log(res);
      if (res.data) {
        res.data.list.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
        });
        this.tableData = res.data;
      }
    },
    //详情
    handleEdit(row) {
      this.$router.push({
        path: "orderdetails",
        query: { id: row.id, page: this.parsm.page },
      });
    },
    // 关闭工单
    closemsg(row) {
      workmsg.closeWork({ id: row.id }).then((res) => {
        this.$message.success(this.$t("lang.Operations"));
        this.getList();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: relative;
}
.messige {
  position: absolute;
  width: 0.1rem;
  height: 0.1rem;
  background: red;
  top: 0.05rem;
  left: 0.2rem;
  border-radius: 50%;
}
.texts {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
/deep/.bottom_tabes{
	width: 1.7rem;
	justify-content: flex-start;
	padding-left: .3rem;
}
</style>
